import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../static/img/logo.png";
import FormLogin from "./components/FormLogin";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#121212",
  color: theme.palette.text.secondary,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export default function Login({ sessionActive, setSessionActive }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionActive) {
      navigate("/dashboard");
    }
  }, [sessionActive, navigate]);

  return (
    <Container maxWidth="xl">
      <Item>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ textAlign: "center", padding: 4 }}>
            <img src={Logo} alt="Logo PM" style={{ maxWidth: "100%", height: "auto" }} />
            <Typography variant="h4" gutterBottom sx={{ marginTop: 3, fontWeight: "700" }}>
              ¡BIENVENIDO A PORTAFOLIO MILLONARIO!
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 4 }}>
              En <span style={{ color: "#DEA550" }}>Portafolio Millonario</span> queremos llevarte a ser un inversor rentable. Contamos con expertos que te ayudarán en este camino de inversiones.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundColor: "#1e1e1e",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 4,
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Iniciar sesión
            </Typography>
            <FormLogin setSessionActive={setSessionActive} />
          </Grid>
        </Grid>
      </Item>
    </Container>
  );
}
