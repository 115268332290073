import { Box, Container, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import imgAgro from "../../static/img/alternativas-inversion/agro.png";
import imgInmobiliaria from "../../static/img/alternativas-inversion/inmobiliaria.png";
import imgNft from "../../static/img/alternativas-inversion/nft.png";
import imgPicoin from "../../static/img/alternativas-inversion/picoin.png";
import imgTrading from "../../static/img/alternativas-inversion/trading.png";
import imgFondo from "../../static/img/alternativas-inversion/fondo.png";
import BlockPage from "./components/BlockPage";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#1F1F1F",
  color: theme.palette.text.secondary,
  margin: "0.5rem",
  borderRadius: "15px !important",
  paddingTop: "5rem",
  paddingLeft: "1rem",
  paddingBottom: "1rem",
}));

export default function AlternativaInversion() {
  return (
    <>
      <BlockPage />

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid md={4} xs={12}>
            <Item
              sx={{
                backgroundImage: `url("${imgAgro}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                AGRO
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Más Inf.
              </Typography>
            </Item>
          </Grid>
          <Grid md={4} xs={12}>
            <Item
              sx={{
                backgroundImage: `url("${imgInmobiliaria}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                INMOBILIARIA
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Más Inf.
              </Typography>
            </Item>
          </Grid>
          <Grid md={4} xs={12}>
            <Item
              sx={{
                backgroundImage: `url("${imgNft}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                NFT
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Más Inf.
              </Typography>
            </Item>
          </Grid>
          <Grid md={4} xs={12}>
            <Item
              sx={{
                backgroundImage: `url("${imgPicoin}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                PICOIN
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Más Inf.
              </Typography>
            </Item>
          </Grid>

          <Grid md={4} xs={12}>
            <Item
              sx={{
                backgroundImage: `url("${imgTrading}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                TRADING DE ALTO RIESGO
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Más Inf.
              </Typography>
            </Item>
          </Grid>

          <Grid md={4} xs={12}>
            <Item
              sx={{
                backgroundImage: `url("${imgFondo}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                FONDO DE INVERSIÓN
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Más Inf.
              </Typography>
            </Item>
          </Grid>

          <Grid md={4} xs={12}>
            <Item>
              <Typography gutterBottom variant="h5" component="div">
                PRÓXIMAMENTE...
              </Typography>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
