import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../static/img/logo.png";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#121212",
  color: theme.palette.text.secondary,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

export default function SessionFail() {
  return (
    <Container maxWidth="xl">
      <Item>
        <img src={Logo} alt="Logo PM" style={{ marginBottom: '20px' }} />
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          NO TIENES UNA SESIÓN ACTIVA
        </Typography>
        <Button component={Link} variant="contained" to="/login" sx={{ marginTop: 2 }}>
          Ingresar
        </Button>
      </Item>
    </Container>
  );
}
