import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import BlockPage from "./components/BlockPage";

export default function Live() {
  return (
    <>
      <BlockPage />

      <Container maxWidth="xl">
        <Grid container spacing={2} sx={{ position: "relative", top: "10rem" }}>
          <Grid md={12} xs={12} className="has-text-centered">
            <Typography gutterBottom variant="h4">
              PROXIMAMENTE
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
