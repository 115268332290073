import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Paper,
  Typography,
  styled,
  Chip,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Modal,
  Button,
  Stack,
} from "@mui/material";
import { TickerTape } from "react-ts-tradingview-widgets";

import InfoIcon from "@mui/icons-material/Info";
import Tether from "../../../static/icons/tether.png";
import Banco from "../../../static/icons/banco.png";
import Tarjetas from "../../../static/img/pmfx/tarjetas.png";

import ChartBalance from "./Components/ChartBalance";
import ChartPercentProfits from "./Components/ChartPercentProfits";
import DepositoForm from "./Components/DepositoForm";
import RetiroForm from "./Components/RetiroForm";
import Investment from "./Components/Investment";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: "#fff",
}));

const style = {
  parent: {
    display: "none",
  },
};

export default function Fondo() {
  const [balance, setBalance] = useState(0);
  const [openModalDeposito, setOpenModalDeposito] = useState(null);
  const [openModalRetiro, setOpenModalRetiro] = useState(null);
  const [profitPercent, setProfitPercent] = useState({ mes: "Mes", profit: 0 });
  const [zoom, setZoom] = useState(false);

  const dataUser = {
    id: Cookies.get("id"),
    jwt: Cookies.get("jwt"),
  };

  const fetchData = useCallback(async () => {
    try {
      const balanceRes = await axios.post(
        `${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_balance`,
        dataUser
      );
      if (balanceRes.status === 200) {
        setBalance(balanceRes.data.balanceTotal);
      }

      const profitRes = await axios.post(
        `${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_profit`,
        dataUser
      );
      if (profitRes.status === 200) {
        setProfitPercent(profitRes.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleModalToggleDeposito = (type) => {
    setOpenModalDeposito(type);
  };

  const handleModalToggleRetiro = (type) => {
    setOpenModalRetiro(type);
  };

  return (
    <>
      <Modal
        open={!!openModalDeposito}
        onClose={() => setOpenModalDeposito(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DepositoForm
          tipoDeposito={openModalDeposito === "usdt" ? 1 : 2}
          setOpenModalDeposito={setOpenModalDeposito}
        />
      </Modal>

      <Modal
        open={!!openModalRetiro}
        onClose={() => setOpenModalRetiro(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RetiroForm
          tipoRetiro={openModalRetiro === "usdt" ? 1 : 2}
          balance={balance}
          setOpenModalRetiro={setOpenModalRetiro}
        />
      </Modal>

      <Grid container spacing={2} sx={{ marginTop: 0, paddingBottom: 5 }}>
        <Grid xs={12} md={12}>
          <TickerTape colorTheme="dark" copyrightStyles={style}></TickerTape>
        </Grid>

        <Grid xs={12} md={8}>
          <Item>
            <Box>
              <Grid container alignItems="flex-end">
                <Grid xs={6}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: "#4B4B4B", fontSize: 25 }}
                  >
                    Balance
                  </Typography>
                </Grid>

                <Grid xs={6} sx={{ textAlign: "right" }}>
                  <Chip
                    label={`${profitPercent.profit}% ${profitPercent.mes}`}
                    sx={{ borderRadius: 1, color: "#D8D8D8", fontSize: 15 }}
                    icon={<InfoIcon />}
                  />
                </Grid>

                <Grid xs={12}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    sx={{ color: "#FFFFFF", fontWeight: 700 }}
                  >
                    {balance} USD
                  </Typography>
                </Grid>

                <Grid xs={12}>
                  <ChartBalance zoom={zoom} />
                </Grid>

                <Grid xs={12}>
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={() => setZoom(false)}>
                      -
                    </Button>
                    <Button variant="contained" onClick={() => setZoom(true)}>
                      +
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>

        <Grid xs={12} md={4}>
          <Item>
            <Typography
              variant="subtitle1"
              sx={{ color: "#4B4B4B", fontSize: 25 }}
            >
              Deposito
            </Typography>
            <List>
              <ListItemButton onClick={() => handleModalToggleDeposito("usdt")}>
                <ListItemAvatar>
                  <Avatar>
                    <img src={Tether} alt="USDT" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Tether"
                  secondary="USDT"
                  sx={{
                    "& span": { fontSize: 25 },
                    "& p": { color: "#6A6A6A" },
                  }}
                />
              </ListItemButton>

              <ListItemButton
                onClick={() => handleModalToggleDeposito("banco")}
              >
                <ListItemAvatar>
                  <Avatar>
                    <img src={Banco} alt="Banco" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Banco"
                  secondary="Cuenta Bancaria"
                  sx={{
                    "& span": { fontSize: 25 },
                    "& p": { color: "#6A6A6A" },
                  }}
                />
              </ListItemButton>
            </List>
          </Item>

          <Item sx={{ marginTop: "1rem" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#4B4B4B", fontSize: 25 }}
            >
              Retiro
            </Typography>
            <List>
              <ListItemButton onClick={() => handleModalToggleRetiro("usdt")}>
                <ListItemAvatar>
                  <Avatar>
                    <img src={Tether} alt="USDT" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Tether"
                  secondary="USDT"
                  sx={{
                    "& span": { fontSize: 25 },
                    "& p": { color: "#6A6A6A" },
                  }}
                />
              </ListItemButton>

              <ListItemButton onClick={() => handleModalToggleRetiro("banco")}>
                <ListItemAvatar>
                  <Avatar>
                    <img src={Banco} alt="Banco" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Banco"
                  secondary="Cuenta Bancaria"
                  sx={{
                    "& span": { fontSize: 25 },
                    "& p": { color: "#6A6A6A" },
                  }}
                />
              </ListItemButton>
            </List>
          </Item>
        </Grid>

        <Grid xs={12} md={5}>
          <Item>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ color: "#4B4B4B", fontSize: 25 }}
            >
              Porcentaje por Mes
            </Typography>
            <ChartPercentProfits />
          </Item>
        </Grid>

        <Grid xs={12} md={7}>
          <Item>
            <Investment />
          </Item>
        </Grid>

        <Grid xs={12} md={6} sx={{ margin: "0 auto", textAlign: "center" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ color: "#B4B4B4", fontSize: 40 }}
          >
            Próximamente
          </Typography>
          <img
            src={Tarjetas}
            alt="Credit Card PMFX"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </>
  );
}
