import { Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import imgFondo from "../../static/img/canabian/fondo.png";
import BlockPage from "./components/BlockPage";

export default function Cannabeach() {
  return (
    <>
      <BlockPage />

      <Container
        maxWidth="xl"
        sx={{
          backgroundImage: `url("${imgFondo}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "unset",
          backgroundPosition: "left",
          height: "84vh",
        }}
      >
        <Grid container spacing={2} sx={{ position: "relative", top: "10rem" }}>
          <Grid md={12} xs={12} className="has-text-centered">
            <Typography gutterBottom variant="h4">
              UNA INVERSIÓN CON INSERCCIÓN EN{" "}
              <strong className="has-text-white">ORO VERDE,</strong>{" "}
              RESGUARDANDO TU CAPITAL EN{" "}
              <strong className="has-text-white">PROPIEDAD INMOBILIARIA</strong>
            </Typography>
            <Button
              href="#"
              target={"_blank"}
              variant="outlined"
              sx={{
                backgroundColor: "#545454",
                borderRadius: "30px",
                borderColor: "#2D2D2D",
                color: "#FFFFFF",
                marginTop: "0.5rem",
                paddingLeft: "2rem",
                paddingRight: "2rem",
              }}
            >
              Más Inf.
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
