import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "80%",
    md: 500,
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AlertMessage = ({ alert }) =>
  alert.show && (
    <Box mt={2}>
      <Alert severity={alert.type}>{alert.message}</Alert>
    </Box>
  );

export default function AccountForm({ setOpenModalAccount }) {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({
    id: "",
    email: "",
    name: "",
    phone: "",
    password: "", // Include password in the state
  });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  // Fetch user data
  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get("https://strapi.pmfondo.com/api/users/me", {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      });
      setUserData({
        id: response.data.id || "",
        email: response.data.email || "",
        name: response.data.name || "",
        phone: response.data.phone || "",
        password: "", // Password should not be fetched for security reasons
      });
    } catch (error) {
      setAlert({
        show: true,
        type: "error",
        message: "Error al obtener los datos del usuario.",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
  
      // Crear un objeto con los datos que se enviarán
      const updatedUserData = {
        name: userData.name,
        phone: userData.phone,
      };
  
      // Solo agregar la contraseña si no está vacía
      if (userData.password) {
        updatedUserData.password = userData.password;
      }
  
      try {
        const response = await axios.put(
          `https://strapi.pmfondo.com/api/users/${userData.id}`,
          updatedUserData, // Enviar solo los datos necesarios
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          setAlert({
            show: true,
            type: "success",
            message: "Datos actualizados con éxito.",
          });
        }
      } catch (error) {
        setAlert({
          show: true,
          type: "error",
          message: "Error al actualizar los datos.",
        });
      } finally {
        setLoading(false);
      }
    },
    [userData]
  );
  

  return (
    <Box sx={style}>
      <IconButton
        onClick={() => setOpenModalAccount(null)}
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>

      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: "#fff", fontSize: 25 }}
      >
        Mi cuenta
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid md={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Correo"
              type="email"
              required
              name="email"
              value={userData.email}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid md={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Nombre"
              required
              name="name"
              value={userData.name}
              onChange={handleChange}
            />
          </Grid>

          <Grid md={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Teléfono"
              required
              name="phone"
              value={userData.phone}
              onChange={handleChange}
            />
          </Grid>

          <Grid md={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Contraseña"
              type="password"
              name="password"
              value={userData.password}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          {!loading ? (
            <Button type="submit" variant="contained">
              Guardar
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>

        <AlertMessage alert={alert} />
      </form>
    </Box>
  );
}
