import {CircularProgress} from "@mui/material";
import Logo from "../static/img/logo.png";

export default function SpinnerPage() {
  return (
    <div
      style={{
        backgroundColor: "#000",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 999999,
      }}
    >
      <img 
        src={Logo} 
        alt="Logo PM" 
        style={{ 
          marginBottom: "20px", 
          maxWidth: "80%", 
          maxHeight: "80%" 
        }} 
      />
      <CircularProgress sx={{ color: "#e1c26f" }} />
    </div>
  );
}
