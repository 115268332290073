import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import Typography from "@mui/material/Typography";
import Card from "../../static/img/black-circle/card.png";
import imgCursos from "../../static/img/black-circle/Unete a nuestros cursos.png";
// import imgPresentacion from "../../static/img/black-circle/Presentacion de negocio.png";
import imgCriptos from "../../static/img/black-circle/Venta de Criptos.png";
import imgCeo from "../../static/img/black-circle/Un Cafe con el CEO.png";
import BlockPage from "./components/BlockPage";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#181818",
  color: theme.palette.text.secondary,
  padding: "1rem",
  borderRadius: "15px",
}));

const ItemBanner = styled(Box)(({ theme }) => ({
  backgroundColor: "#181818",
  color: theme.palette.text.secondary,
  borderRadius: "15px",
  marginTop: "2rem",
}));

const BlackCircle = () => {
  const [valueDate, setValueDate] = useState(new Date());

  return (
    <>
      <BlockPage />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid md={6} xs={12}>
            <Item sx={{ flexGrow: 1 }}>
              <Calendar
                onChange={setValueDate}
                value={valueDate}
                onClickDay={(value, event) =>
                  console.log("Clicked day: ", value)
                }
              />
            </Item>
          </Grid>
          <Grid md={6} xs={12} sx={{ textAlign: "center" }}>
            <img src={Card} alt="Card" style={{ width: "70%" }} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid md={6} xs={12}>
            <ItemBanner
              sx={{
                backgroundImage: `url("${imgCursos}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "right",
              }}
            >
              <Grid container spacing={2}>
                <Grid md={7} xs={12}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ lineHeight: "28px" }}
                    className="has-text-white has-text-weight-bold	"
                  >
                    Únete a <br /> nuestros cursos
                  </Typography>

                  <p className="is-size-7">
                    Te ofrecemos diferentes cursos de <br /> trading pensados
                    para ti
                  </p>
                  <Button
                    type="submit"
                    variant="contained"
                    className="mt-1"
                    sx={{ backgroundColor: "#2e2e2e", color: "#fff" }}
                  >
                    Mas info.
                  </Button>
                </Grid>
              </Grid>
            </ItemBanner>

            {/* <ItemBanner
            sx={{
              backgroundImage: `url("${imgPresentacion}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "unset",
              backgroundPosition: "left",
            }}
          >
            <Grid container spacing={2}>
            <Grid md={6} xs={12}></Grid>
              <Grid md={6} xs={12}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ lineHeight: "28px" }}
                  className="has-text-white has-text-weight-bold	"
                >
                  Únete a <br /> nuestros cursos
                </Typography>

                <p className="is-size-7">
                  Te ofrecemos diferentes cursos de <br /> trading pensados para
                  ti
                </p>
                <Button
                  type="submit"
                  variant="contained"
                  className="mt-1"
                  sx={{ backgroundColor: "#2e2e2e", color: "#fff" }}
                >
                  Mas info.
                </Button>
              </Grid>
            </Grid>
          </ItemBanner> */}

            <ItemBanner
              sx={{
                backgroundImage: `url("${imgCeo}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
              }}
            >
              <Grid container spacing={2}>
                <Grid md={6} xs={12}></Grid>
                <Grid md={6} xs={12}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ lineHeight: "28px" }}
                    className="has-text-white has-text-weight-bold	"
                  >
                    Café con el CEO
                  </Typography>

                  <p className="is-size-7">
                    Podcast, uns espacio en donde hablamos temas del trading y
                    los mercados financieros
                  </p>
                  <Button
                    type="submit"
                    variant="contained"
                    className="mt-1"
                    sx={{ backgroundColor: "#2e2e2e", color: "#fff" }}
                  >
                    Mas info.
                  </Button>
                </Grid>
              </Grid>
            </ItemBanner>

            <ItemBanner
              sx={{
                backgroundImage: `url("${imgCriptos}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "right",
              }}
            >
              <Grid container spacing={2}>
                <Grid md={7} xs={12}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ lineHeight: "28px" }}
                    className="has-text-white has-text-weight-bold	"
                  >
                    Venta de Criptos
                  </Typography>

                  <p className="is-size-7">
                    Te ofrecemos gran variedad de criptos <br /> entre ellas
                    USDT, BITCOIN, TETHER, <br /> entre otras, para la venta.
                  </p>
                  <Button
                    type="submit"
                    variant="contained"
                    className="mt-1"
                    sx={{ backgroundColor: "#2e2e2e", color: "#fff" }}
                    component={Link}
                    to="/ruta-del-enlace"
                  >
                    Mas info.
                  </Button>
                </Grid>
              </Grid>
            </ItemBanner>
          </Grid>
          <Grid md={6} xs={12} sx={{ textAlign: "center" }}>
            <Item sx={{ flexGrow: 1, height: "100vh" }} className="mt-5"></Item>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BlackCircle;
