import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Login from "./pages/Login/Login";
// import Registro from "./pages/Registro";
import DashboardPage from "./pages/Dashboard/Dashboard";
import Fondo from "./pages/Dashboard/Fondo/Fondo";
import BlackCircle from "./pages/Dashboard/BlackCircle";
import PmCoin from "./pages/Dashboard/PmCoin";
import Contratos from "./pages/Dashboard/Contratos";
import AlternativaInversion from "./pages/Dashboard/AlternativaInversion";
import Cannabeach from "./pages/Dashboard/Cannabeach";
import Live from "./pages/Dashboard/Live";
import axios from "axios";
import Cookies from "js-cookie";
import SpinnerPage from "./components/SpinnerPage";

// Tema personalizado
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#C3900C" },
    secondary: { main: "#C3900C" },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

function App() {
  const [sessionActive, setSessionActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Manejo de la sesión
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/me`,
          { headers: { Authorization: `Bearer ${Cookies.get("jwt")}` } }
        );
        if (response.status === 200) {
          setSessionActive(true);
        }
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };
    fetchSession();
  }, []);

  // Manejo del estado de carga (spinner)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {isLoading && <SpinnerPage />}

      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Login
                  sessionActive={sessionActive}
                  setSessionActive={setSessionActive}
                />
              }
            />
            <Route
              path="/login"
              element={
                <Login
                  sessionActive={sessionActive}
                  setSessionActive={setSessionActive}
                />
              }
            />
            {/* <Route
              path="/registro"
              element={<Registro sessionActive={sessionActive} />}
            /> */}
            <Route
              path="/dashboard"
              element={
                <DashboardPage
                  sessionActive={sessionActive}
                  setSessionActive={setSessionActive}
                />
              }
            >
              <Route index element={<Fondo />} />
              <Route path="fondo" element={<Fondo />} />
              <Route path="black-circle" element={<BlackCircle />} />
              <Route path="pm-coin" element={<PmCoin />} />
              <Route path="contratos" element={<Contratos />} />
              <Route path="alternativa-inversion" element={<AlternativaInversion />} />
              <Route path="cannabeach" element={<Cannabeach />} />
              <Route path="live" element={<Live />} />
              <Route path="*" element={<DashboardPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
