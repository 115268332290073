import React, { useState, useCallback } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Typography, TextField, Button, Alert, IconButton, CircularProgress} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";
import Qr from "../../../../static/img/qr-wallet.jpeg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%", // Para pantallas pequeñas (móviles)
    sm: "80%", // Para pantallas medianas (tablets)
    md: 500, // Para pantallas más grandes (desktops)
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AlertMessage = ({ alert }) =>
  alert.show && (
    <Box mt={2}>
      <Alert severity={alert.type}>{alert.message}</Alert>
    </Box>
  );

const QRCodeSection = ({ tipoDeposito }) =>
  tipoDeposito === 1 && (
    <Box sx={{ textAlign: "center" }}>
      <img src={Qr} alt="QR Wallet" style={{ width: "30%" }} />
      <Typography
        variant="subtitle2"
        gutterBottom
        sx={{ color: "#fff", fontSize: 15, marginBottom: 4 }}
      >
        TUe6ZC5PWJFnkqqDSG6jmAX2i2spz9gmBt
      </Typography>
    </Box>
  );

export default function DepositoForm({ tipoDeposito, setOpenModalDeposito }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    amount: "",
    currency: "USD",
    type: { id: 1 },
    product: { id: 1 },
    user: { id: Cookies.get("id") },
    image: "",
    reference: "",
  });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const handleChange = useCallback((e) => {
    const { name, value, files } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          amount: data.amount,
          currency: data.currency,
          type: data.type.id,
          product: data.product.id,
          user: { id: data.user.id },
          reference: data.reference,
          date: new Date(),
        })
      );
      formData.append("files.image", data.image);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/operations`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          setAlert({
            show: true,
            type: "success",
            message: "Depósito enviado con éxito.",
          });
        }
      } catch (error) {
        setAlert({
          show: true,
          type: "error",
          message: "Error en el depósito.",
        });
      } finally {
        setLoading(false);
      }
    },
    [data]
  );

  return (
    <Box sx={style}>
      <IconButton
        onClick={() => setOpenModalDeposito(null)}
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>

      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: "#fff", fontSize: 25 }}
      >
        Depósito
      </Typography>

      <QRCodeSection tipoDeposito={tipoDeposito} />

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid md={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Monto"
              type="number"
              required
              name="amount"
              value={data.amount}
              onChange={handleChange}
            />
          </Grid>

          <Grid md={12} xs={12}>
            <label style={{ color: "#4B4B4B" }}>Comprobante</label>
            <TextField
              type="file"
              accept=".png,.jpg,.jpeg,.pdf"
              name="image"
              required
              onChange={handleChange}
            />
          </Grid>

          <Grid md={12} xs={12}>
            <label style={{ color: "#4B4B4B" }}>
              {tipoDeposito === 1
                ? "Hash de la operación (Opcional)"
                : "Número de referencia"}
            </label>
            <TextField
              fullWidth
              variant="outlined"
              label={tipoDeposito === 1 ? "Hash" : "Referencia"}
              name="reference"
              value={data.reference}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          {!loading ? (
            <Button type="submit" variant="contained">
              Enviar
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>

        <AlertMessage alert={alert} />
      </form>
    </Box>
  );
}
