import React, { useState, useCallback } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%", // Para pantallas pequeñas (móviles)
    sm: "80%", // Para pantallas medianas (tablets)
    md: 500, // Para pantallas más grandes (desktops)
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AlertMessage = ({ alert }) =>
  alert.show && (
    <Box mt={2}>
      <Alert severity={alert.type}>{alert.message}</Alert>
    </Box>
  );

export default function DepositoForm({
  tipoRetiro,
  balance,
  setOpenModalRetiro,
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    amount: "",
    currency: "USD",
    type: { id: 2 },
    product: { id: 1 },
    user: { id: Cookies.get("id") },
    reference: "",
  });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const handleChange = useCallback((e) => {
    const { name, value, files } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (parseFloat(data.amount) > balance) {
        setAlert({
          show: true,
          type: "error",
          message: "No puedes exceder el límite de tu balance.",
        });
        return;
      }

      setLoading(true);

      let referenceText = "";

      if (tipoRetiro === 1) {
        referenceText = "USDT TRC-20: " + data.reference;
      } else if (tipoRetiro === 2) {
        referenceText = "Cuenta Bancaria: " + data.reference;
      }

      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          amount: data.amount,
          currency: data.currency,
          type: data.type.id,
          product: data.product.id,
          user: { id: data.user.id },
          reference: referenceText,
          date: new Date(),
        })
      );

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/operations`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          setAlert({
            show: true,
            type: "success",
            message: "Depósito enviado con éxito.",
          });
        }
      } catch (error) {
        setAlert({
          show: true,
          type: "error",
          message: "Error en el depósito.",
        });
      } finally {
        setLoading(false);
      }
    },
    [data, balance, tipoRetiro]
  );

  return (
    <Box sx={style}>

      <IconButton
        onClick={() => setOpenModalRetiro(null)}
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>

      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: "#fff", fontSize: 25 }}
      >
        {tipoRetiro === 1
          ? "Retiro en Criptomonedas"
          : "Retiro en cuenta bancaria"}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid md={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Monto"
              type="number"
              required
              name="amount"
              value={data.amount}
              onChange={handleChange}
            />
          </Grid>

          <Grid md={12} xs={12}>
            <label style={{ color: "#4B4B4B" }}>
              {tipoRetiro === 1
                ? "Wallet USDT en red TRC-20"
                : "Datos de la cuenta bancaria"}
            </label>
            <TextField
              fullWidth
              variant="outlined"
              label={tipoRetiro === 1 ? "Wallet" : "Datos bancarios"}
              name="reference"
              value={data.reference}
              required
              onChange={handleChange}
              multiline
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          {!loading ? (
            <Button type="submit" variant="contained">
              Enviar
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>

        <AlertMessage alert={alert} />
      </form>
    </Box>
  );
}
