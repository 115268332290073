import React, { useState, useEffect } from "react";
import { Box, Button, Container, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import Cookies from "js-cookie";

import iconDocument from "../../static/img/contratos/icon-document.png";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#1F1F1F",
  color: theme.palette.text.secondary,
  margin: "0.5rem",
  textAlign: "center",
  paddingTop: "2rem",
  paddingLeft: "0.8rem",
  paddingRight: "0.8rem",
  borderRadius: "15px",
  height: "250px",
}));

export default function Contratos() {
  const [dataFiles, setDataFiles] = useState([]);

  useEffect(() => {
    axios
      .get("https://strapi.pmfondo.com/api/users/me?populate=*", {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          axios
            .get(
              `https://strapi.pmfondo.com/api/files-users/${response.data.files_user.id}?populate=*`,
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("jwt")}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                setDataFiles(response.data.data.attributes.contracts.data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   console.log(dataFiles.length);
  // }, [dataFiles]);

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {dataFiles.map((item, key) => (
            <Grid md={3} xs={12} key={key}>
              <Item>
                <img src={iconDocument} alt="" />

                <Typography
                  variant="h4"
                  className="is-size-6 has-text-weight-light hast-text-centered mt-3"
                >
                  {item.attributes.name} <br />
                  <br />
                </Typography>
                <Button
                  href={`https://api.portafoliomillonario.com${item.attributes.url}`}
                  target={"_blank"}
                  variant="outlined"
                  sx={{
                    backgroundColor: "#333333",
                    borderRadius: "30px",
                    borderColor: "#2D2D2D",
                    color: "#FFFFFF",
                    marginTop: "0.5rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }}
                >
                  Ver mas
                </Button>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
