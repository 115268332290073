import {
  Box,
  Button,
  Container,
  styled,
  Typography,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import iconCoin from "../../static/img/pm-coin/icon-coin.png";
import iconChart from "../../static/img/pm-coin/icon-chart.png";
import imgTether from "../../static/img/pm-coin/tether.png";
import imgBtc from "../../static/img/pm-coin/btc.png";
import imgBnb from "../../static/img/pm-coin/bnb.png";
import imgPmc from "../../static/img/pm-coin/pmc.png";
import BlockPage from "./components/BlockPage";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#1F1F1F",
  color: theme.palette.text.secondary,
  margin: "0.5rem",
  textAlign: "center",
  paddingBottom: "1.5rem",
  borderRadius: "15px",
}));

export default function PmCoin() {
  return (
    <>
    <BlockPage />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid md={12} xs={12}>
            <iframe
              title="Divisas"
              scrolling="no"
              allowtransparency="true"
              frameBorder="0"
              src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=es#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22US%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22Ethereum%22%7D%2C%7B%22description%22%3A%22GBPAUD%22%2C%22proName%22%3A%22FX%3AGBPAUD%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A76%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fticker-tape%2F%22%7D"
              style={{
                backgroundColor: "#212121",
                boxSizing: "border-box",
                display: "block",
                height: "50px",
                width: "100%",
              }}
            ></iframe>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: "#1F1F1F",
            color: "#fff",
            marginTop: "2rem",
            borderRadius: "15px",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Grid md={7} xs={12} className="pl-5">
            <h2 className="is-size-1 has-text-weight-light">
              Saldo: <strong className="has-text-white">120PM</strong>
            </h2>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "30px",
                borderColor: "#2D2D2D",
                color: "#FFFFFF",
              }}
            >
              ULTMOS MOV.{" "}
            </Button>
          </Grid>

          <Grid md={5} xs={12}>
            <Stack direction="row" spacing={2}>
              <img src={iconCoin} alt="icon-coin" style={{ height: "100%" }} />

              <h2 className="is-size-3 has-text-weight-light">
                <strong className="has-text-white"> 1 PM</strong>Coin = 1 USDT
              </h2>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="pb-5 pt-5">
          <Grid md={12} xs={12}>
            <Stack direction="row" spacing={2}>
              <img
                src={iconChart}
                alt="icon-chart"
                style={{ height: "100%", marginTop: "5px" }}
              />
              <h2 className="is-size-4 has-text-weight-light">
                COMPRA DE CRIPTOMONEDAS
              </h2>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid md={3} xs={12}>
            <Item>
              <img src={imgTether} alt="" />
              <Typography
                variant="h4"
                className="is-size-3 has-text-weight-light hast-text-centered"
                sx={{ marginTop: "-2.5rem" }}
              >
                Tether
              </Typography>
              <h4 className="is-size-5">USDT</h4>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#333333",
                  borderRadius: "30px",
                  borderColor: "#2D2D2D",
                  color: "#FFFFFF",
                  marginTop: "0.5rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                Comprar
              </Button>
            </Item>
          </Grid>
          <Grid md={3} xs={12}>
            <Item>
              <img src={imgBtc} alt="" />

              <Typography
                variant="h4"
                className="is-size-3 has-text-weight-light hast-text-centered"
                sx={{ marginTop: "-2.5rem" }}
              >
                Bitcoin
              </Typography>
              <h4 className="is-size-5">BTC</h4>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#333333",
                  borderRadius: "30px",
                  borderColor: "#2D2D2D",
                  color: "#FFFFFF",
                  marginTop: "0.5rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                Comprar
              </Button>
            </Item>
          </Grid>
          <Grid md={3} xs={12}>
            <Item>
              <img src={imgBnb} alt="" />

              <Typography
                variant="h4"
                className="is-size-3 has-text-weight-light hast-text-centered"
                sx={{ marginTop: "-2.5rem" }}
              >
                BNB
              </Typography>
              <h4 className="is-size-5">BNB</h4>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#333333",
                  borderRadius: "30px",
                  borderColor: "#2D2D2D",
                  color: "#FFFFFF",
                  marginTop: "0.5rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                Comprar
              </Button>
            </Item>
          </Grid>
          <Grid md={3} xs={12}>
            <Item>
              <img src={imgPmc} alt="" />

              <Typography
                variant="h4"
                className="is-size-3 has-text-weight-light hast-text-centered"
                sx={{ marginTop: "-3rem" }}
              >
                PMC
              </Typography>
              <h4 className="is-size-5">PM</h4>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#333333",
                  borderRadius: "30px",
                  borderColor: "#2D2D2D",
                  color: "#FFFFFF",
                  marginTop: "0.5rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                Comprar
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
