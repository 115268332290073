import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import axios from "axios";
import Cookies from "js-cookie";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import ZoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ZoomPlugin
);

// Configuración del gráfico (opciones)
const chartOptions = {
  responsive: true,
  plugins: {
    legend: { position: "top", display: false },
    title: { display: false, text: "Grafica" },
  },
  scales: {
    y: {
      grid: { color: "rgba(40, 40, 40, 1)" },
      ticks: { color: "#898989" },
    },
    x: {
      grid: { color: "rgba(40, 40, 40, 1)" },
      ticks: { color: "#898989" },
    },
  },
  maintainAspectRatio: false,
};

// Plugin para el gradiente del gráfico
const gradientPlugin = {
  beforeInit: (chart) => {
    chart.data.datasets.forEach((dataset) => {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 85);
      gradient.addColorStop(0, "rgba(225, 169, 25, 1)");
      gradient.addColorStop(1, "rgba(225, 169, 25, 0.1)");
      dataset.backgroundColor = gradient;
      dataset.borderColor = "rgba(225, 169, 25, 1)";
      dataset.borderWidth = 2;
    });
  },
};

// Función para las peticiones a la API
const fetchChartData = async (url, dataUser) => {
  try {
    const response = await axios.post(url, dataUser);
    return response.status === 200 ? response.data : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default function ChartBalance({ zoom }) {
  const [data, setData] = useState({ fechas: [0], saldos: [0] });
  const [dataZoom, setDataZoom] = useState({ fechas: [0], saldos: [0] });
  const chartRef = useRef(null); // Reemplaza useState para chartInstance

  useEffect(() => {
    const dataUser = {
      id: Cookies.get("id"),
      jwt: Cookies.get("jwt"),
    };

    // Inicializa los datos del gráfico
    const initChartData = async () => {
      const initialData = await fetchChartData(
        `${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_grafico`,
        dataUser
      );
      if (initialData) setData(initialData);
    };

    initChartData();

    // Socket para actualizar datos en tiempo real
    const socket = io(`${process.env.REACT_APP_API_SIGNALS_URL}`);

    socket.on("signal_balance", async (data) => {
      if (data.status === 1) {
        const [tradingData, zoomData] = await Promise.all([
          fetchChartData(`${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_trading`, {
            ...dataUser,
            max: data.max,
            min: data.min,
          }),
          fetchChartData(`${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_grafico_ultimos`, {
            ...dataUser,
            max: data.max,
            min: data.min,
          }),
        ]);
        if (tradingData) setData(tradingData);
        if (zoomData) setDataZoom(zoomData);
      } else {
        const [tradingData, zoomData] = await Promise.all([
          fetchChartData(`${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_grafico`, {
            ...dataUser,
            max: data.max,
            min: data.min,
          }),
          fetchChartData(`${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_grafico_ultimos1`, {
            ...dataUser,
            max: data.max,
            min: data.min,
          }),
        ]);
        if (tradingData) setData(tradingData);
        if (zoomData) setDataZoom(zoomData);
      }
    });

    // Cleanup del socket al desmontar el componente
    return () => socket.disconnect();
  }, []);

  const chartData = {
    labels: zoom ? dataZoom.fechas : data.fechas,
    datasets: [
      {
        id: 1,
        fill: true,
        label: "USD",
        data: zoom ? dataZoom.saldos : data.saldos,
      },
    ],
  };

  return (
    <Line
      datasetIdKey="id"
      options={chartOptions}
      data={chartData}
      plugins={[gradientPlugin]}
      width={100}
      height={265}
      ref={chartRef}
    />
  );
}
