import React from "react";
import { Box } from "@mui/material";
import { TbLockSquareRoundedFilled } from "react-icons/tb";

export default function BlockPage() {
  return (
    <Box
      sx={{
        backgroundColor: "#000000d1",
        position: "fixed",
        height: "100vh",
        width: "100%",
        zIndex: 999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TbLockSquareRoundedFilled
        style={{
          color: "#e1c26f",
          fontSize: "5rem",
          position: "relative",
          top: "-10%",
          right: "10%",
        }}
      />
    </Box>
  );
}
